<template>
  <product title="Amazfit 智能运动手表 2"
    :swiper-data="swiperData"
    :slogan="slogan"
    price="999"
    :colors="colors"
    :relatived-products="relativedProducts"
    :detail-images="detailImages"
    :param-images="paramImages"
    :video="video"
    buy-link="https://j.youzan.com/gjQ1Kh"
  ></product>
</template>

<script>
import { CDN_URL, RELATIVED_PRODUCTS } from '@/config'
import Product from '../Product'

export default {
  name: 'stratos',
  components: {
    Product
  },
  data () {
    return {
      slogan: '陶瓷表圈 | 50米防水 | 铁人三项 | VO2max<br/>5 天续航 | 支付宝离线支付 | 蓝牙音乐播放',
      colors: [
        '黑色'
      ],
      video: {
        url: 'https://site-cdn.huami.com/files/amazfit/amazfit-cn/videos/stratos.mp4',
        poster: CDN_URL + '/images/poster/stratos.png'
      },
      swiperData: [
        [
          CDN_URL + '/images/product/stratos/swiper/1.png',
          CDN_URL + '/images/product/stratos/swiper/2.png',
          CDN_URL + '/images/product/stratos/swiper/3.png',
          CDN_URL + '/images/product/stratos/swiper/4.png'
        ]
      ],
      relativedProducts: RELATIVED_PRODUCTS,
      detailImages: [
        CDN_URL + '/images/product/stratos/detail/01w.jpg',
        CDN_URL + '/images/product/stratos/detail/02.jpg',
        CDN_URL + '/images/product/stratos/detail/03.jpg',
        CDN_URL + '/images/product/stratos/detail/04.jpg',
        CDN_URL + '/images/product/stratos/detail/05.jpg',
        CDN_URL + '/images/product/stratos/detail/06.jpg',
        CDN_URL + '/images/product/stratos/detail/07.jpg',
        CDN_URL + '/images/product/stratos/detail/09.jpg'
      ],
      paramImages: [
        CDN_URL + '/images/common/zepp-white.jpg',
        CDN_URL + '/images/product/stratos/detail/11_2.jpg'
      ]
    }
  },
  methods: {

  }
}
</script>

<style>
</style>
